<template>
  <div class="sb_view_choose-role">
    <sb-logo-full class="sb_view_choose-role_logo" />
    <div class="sb_view_choose-role_content">
      <div class="sb_view_choose-role_content_top">
        <h1>Kies je rol</h1>
        <sb-spacer height="20" />
        <p>Onder welke rol wil je het beheer bekijken?</p>
        <sb-spacer height="20" />
      </div>
      <div class="sb_view_choose-role_content_bottom">
        <sb-dropdown-button label="Kies een rol">
          <router-link to="/admin">
            <i-button type="text"> Als Admin </i-button>
          </router-link>
          <router-link to="/admin">
            <i-button type="text"> Als coach </i-button>
          </router-link>
        </sb-dropdown-button>
      </div>
    </div>

    <sb-spacer height="40" />
  </div>
</template>

<script>
import SbDropdownButton from '@/components/SbDropdownButton';
import SbLogoFull from '@/components/SbLogoFull';

export default {
  name: 'ChooseRole',
  components: {
    SbDropdownButton,
    SbLogoFull,
  },
};
</script>

<style lang="scss">
.sb_view_choose-role {
  display: flex;
  flex-direction: column;
  align-items: center;
  background: $brand-lightest-gray;
  min-height: 100vh;
  background-image: url('/static/img/bg/leesetende-plant.jpg');
  background-repeat: no-repeat;
  background-position: bottom -10px left 20%;

  &_logo {
    padding: 100px 0 40px 0;
  }

  &_content {
    background: $brand-white;
    border-radius: 10px;
    box-shadow: 0 0 30px rgba($brand-black, 0.1),
      0 5px 5px rgba($brand-black, 0.1);
    width: 100%;
    max-width: 600px;
    &_top {
      padding: 40px;
    }
    &_bottom {
      padding: 30px 40px;
      background: $brand-lightest-gray;
      border-bottom-left-radius: 10px;
      border-bottom-right-radius: 10px;
      display: flex;
      justify-content: space-between;
    }
  }
}
</style>
