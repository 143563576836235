<template>
  <div class="sb_dropdown-button">
    <dropdown trigger="click" placement="bottom-start">
      <div
        class="sb_dropdown-button_btn-items"
        :style="{ width: width + 'px' }"
      >
        {{ label }}
        <icon type="ios-arrow-down" />
      </div>
      <dropdown-menu
        slot="list"
        style="width: 300px; padding: 20px"
        class="sb_dropdown-button_content-slot"
      >
        <slot></slot>
      </dropdown-menu>
    </dropdown>
  </div>
</template>

<script>
export default {
  name: 'SbDropdownButton',
  props: {
    /**
     * The label of the dropdown button
     */
    label: {
      type: String,
      default: '',
    },
    /**
     * The width of the dropdown button
     */
    width: {
      type: Number,
      default: 200,
    },
  },
};
</script>

<style lang="scss">
.sb_dropdown-button {
  &_btn-items {
    width: 100%;
    padding: 9px 16px;
    border: solid 2px #dddee2;
    border-radius: 8px;
    transition: border-color 0.3s ease;
    user-select: none;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: $brand-white;

    .ivu-icon {
      transition: transform 0.2s ease;
    }

    &:hover {
      cursor: pointer;
      border-color: $brand-primary;
    }
  }
  &_content-slot {
    > .ivu-checkbox-group {
      > .ivu-checkbox-group-item {
        display: block !important;
        margin: 0 0 5px 0;
      }
    }
    > .ivu-radio-group {
      > .ivu-radio-group-item {
        display: block !important;
        margin: 0 0 5px 0;
      }
    }
  }
}
</style>
